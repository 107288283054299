import React from 'react';
//import remark from 'remark';
//import remarkHtml from 'remark-html';
import Slider from 'react-slick';
import TestimonialBlockLP from './TestimonialBlockLP';
import '../../styles/components/landing-page/testimonial-slider.scss';
import StaticBannerLP from '../../components/LandingPage/StaticBannerLP';

const TestimonialSlider = ({testimonials, bgImage, title}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <br />,
    prevArrow: <br />,
  };

  return (
    <div className="testimonial-slider-container">
      <div className="testimonial-slider-bg">
        <StaticBannerLP image={bgImage} />
        <div className="testimonial-slider-bg-filter" />
      </div>

      <div className="testimonial-slider-content-wrapper">
        <div className="testimonial-slider-title typo-lp-testimonial-title">
          {title}
        </div>
        <div className="testimonial-slider-wrapper">
          <Slider {...settings}>
            {testimonials &&
              testimonials.map (d => (
                <TestimonialBlockLP
                  key={d.id}
                  text={d.text}
                  name={d.name}
                  role={d.role}
                  company={d.company}
                />
              ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSlider;

/*
import React from 'react';
import remark from 'remark';
import remarkHtml from 'remark-html';
import '../../styles/components/case-templates/testimonial.scss';

const Testimonial = ({text, name, role, paddingTop, paddingBottom}) => {
  var content = remark ().use (remarkHtml).processSync (text).toString ();

  return (
    <div
      className="testimonial-block-wrapper"
      style={
        text &&
        name &&
        role && {
          paddingTop: paddingTop,
          paddingBottom: paddingBottom,
        }
      }
    >
      {text &&
        name &&
        role &&
        <div className="testimonial-block-container">
          <div className="testimonial-intro-symbol">
            <svg width="65px" height="65px">
              <path
                fill="#0033A0"
                d="M13.7771739,59.1925926 C17.3097826,59.1925926 20.3419384,57.9965142 22.8736413,55.6043573 C25.4053442,53.2122004 26.6711957,50.2818083 26.6711957,46.8131808 C26.6711957,43.1053377 25.4053442,40.0852397 22.8736413,37.7528867 C20.3419384,35.4205338 16.8387681,34.2543573 12.3641304,34.2543573 L12.3641304,34.2543573 L11.8342391,34.2543573 C13.2472826,29.7092593 15.7201087,25.5229847 19.2527174,21.6955338 C22.7853261,17.8680828 26.259058,14.9974946 29.673913,13.0837691 C28.9673913,9.8543573 27.4365942,7.94063181 25.0815217,7.34259259 C17.5452899,12.7249455 11.4809783,18.2867102 6.88858696,24.0278867 C2.29619565,29.7690632 0,36.2876906 0,43.5837691 C0,47.8896514 1.23641304,51.5675926 3.70923913,54.6175926 C6.18206522,57.6675926 9.53804348,59.1925926 13.7771739,59.1925926 Z M49.1032609,59.1925926 C52.6358696,59.1925926 55.6680254,57.9965142 58.1997283,55.6043573 C60.7314312,53.2122004 61.9972826,50.2818083 61.9972826,46.8131808 C61.9972826,43.1053377 60.7314312,40.0852397 58.1997283,37.7528867 C55.6680254,35.4205338 52.1648551,34.2543573 47.6902174,34.2543573 L47.6902174,34.2543573 L47.1603261,34.2543573 C48.5733696,29.7092593 51.0461957,25.5229847 54.5788043,21.6955338 C58.111413,17.8680828 61.5851449,14.9974946 65,13.0837691 C64.2934783,9.8543573 62.7626812,7.94063181 60.4076087,7.34259259 C52.8713768,12.7249455 46.8070652,18.2867102 42.2146739,24.0278867 C37.6222826,29.7690632 35.326087,36.2876906 35.326087,43.5837691 C35.326087,47.8896514 36.5625,51.5675926 39.0353261,54.6175926 C41.5081522,57.6675926 44.8641304,59.1925926 49.1032609,59.1925926 Z"
              />

            </svg>
          </div>
          <div
            className="testimonial-text"
            dangerouslySetInnerHTML={{__html: content}}
          />
          <div className="client-recommendation">
            <div className="name-recommendation">{name}</div>
            <div className="role-recommendation">{role}</div>
          </div>
        </div>}
    </div>
  );
};

export default Testimonial;

*/
