import React from 'react';
import '../../styles/components/landing-page/features-lp.scss';
import FeaturesCardLP from './FeaturesCardLP';

const FeaturesLP = ({content}) => {
  return (
    <div className="features-container">
      {content.map ((d, i) => (
        <FeaturesCardLP key={i} icon={d.icon} title={d.title} text={d.text} />
      ))}
    </div>
  );
};

export default FeaturesLP;
