import React, {Component} from 'react';
import '../../styles/components/landing-page/form-lp.scss';
import axios from 'axios';
import '../../styles/utils/general.scss';
import {validateEmail} from '../../utils/helpers';
import ReactGA from 'react-ga';

const INITIAL_STATE = {
  name: '',
  email: '',
  message: '',
  to: 'info@3decide.com',
  tid: 'd-d88efab6754940efa00092f08033e655',
  error: null,
  mailSent: null,
  hasStartedToFillForm: false,
};
const API_PATH = 'http://sendmail.monade.tech/sendmail';

class FormLP extends Component {
  state = {...INITIAL_STATE};

  onSubmit = event => {
    event.preventDefault ();

    if (validateEmail (this.state.email)) {
      axios ({
        method: 'post',
        url: `${API_PATH}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        data: JSON.stringify (this.state),
      })
        .then (result => {
          ReactGA.event ({
            category: 'Landing Page',
            action: 'Send Form',
          });

          this.setState ({
            mailSent: result.data.status,
          });
        })
        .catch (error => {
          this.setState ({error: error.message}); //not being used
        });
    } else {
      this.setState ({
        mailSent: false,
      });
    }
  };

  componentDidUpdate (prevProps, prevState) {
    if (prevState !== this.state) {
      if (this.state.hasStartedToFillForm === false) {
        if (this.state.name || this.state.email || this.state.message) {
          ReactGA.event ({
            category: 'Landing Page',
            action: 'Started to fill Form',
          });
        }

        this.setState ({hasStartedToFillForm: true});
      }
    }
  }

  render () {
    const {name, email, message} = this.state;

    const isInvalid = message === '' || email === '' || name === '';

    return (
      <div className="lp-form-component-container">
        <div className="lp-form-component-wrapper">
          <div className="lp-form-title typo-lp-form-title">

            {this.props.locale === 'en'
              ? 'Get in touch with us'
              : 'Entre em contato connosco'}

          </div>

          <form onSubmit={this.onSubmit}>

            <label
              htmlFor="input"
              className="lp-form-label typo-lp-form-labels
"
            >
              {this.props.locale === 'en' ? 'Name' : 'Nome'}

            </label>

            <input
              className="name-input-lp "
              name="name"
              value={name}
              onChange={event =>
                this.setState ({[event.target.name]: event.target.value})}
              type="text"
              style={{height: '48px'}}
            />
            <label
              htmlFor="input"
              className="lp-form-label typo-lp-form-labels
"
            >
              Email
            </label>

            <input
              className="email-input-lp"
              name="email"
              value={email}
              onChange={event =>
                this.setState ({[event.target.name]: event.target.value})}
              type="text"
              style={{height: '48px'}}
            />
            <label
              htmlFor="input"
              className="lp-form-label typo-lp-form-labels"
            >
              {this.props.locale === 'en' ? 'Message' : 'Mensagem'}
            </label>
            <textarea
              rows="5"
              className="message-input"
              name="message"
              value={message}
              onChange={event =>
                this.setState ({[event.target.name]: event.target.value})}
            />

            <div className="feedback-messages typo-captions-red">
              {this.state.mailSent &&
                <div className="success-feedback typo-captions-green">
                  {this.props.locale === 'en'
                    ? 'Thank you for contacting us'
                    : 'Obrigado por nos contactar'}
                </div>}
              {this.state.mailSent !== null &&
                !this.state.mailSent &&
                (this.props.locale === 'en'
                  ? <div className="error-feedback">
                      Invalid email. Please try again.
                    </div>
                  : <div className="error-feedback">
                      Email inválido. Por favor tente novamente.
                    </div>)}
            </div>
            <div
              className="lp-form-button-wrapper"
              style={{position: 'relative'}}
            >
              <button
                disabled={isInvalid}
                type="submit"
                className="button-primary"
                style={{
                  lineHeight: '16px',
                  textTransform: 'uppercase',
                  fontFamily: 'Nunito Sans',
                  width: '100%',
                  height: '55px',
                  fontWeight: '700',
                }}
              >
                {this.props.locale === 'en'
                  ? 'Book your demo'
                  : 'Marque a sua demo'}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default FormLP;

//{error && <p className="typo-captions-red">{error.message}</p>}
