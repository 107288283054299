import React from 'react';
import '../../styles/components/landing-page/features-card-lp.scss';

const loadLogo = logoName => {
  switch (logoName) {
    case 'dialog':
      return (
        <svg
          width="70"
          height="70"
          viewBox="0 0 70 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="35" cy="35" r="35" fill="#E6EEFF" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.75 23.1666C25.2857 23.1666 21.6666 26.7857 21.6666 31.25C21.6666 35.7143 25.2857 39.3333 29.75 39.3333H35.1666V44.3597L39.9747 39.3554L40.638 39.3242C44.9214 39.1224 48.3333 35.5842 48.3333 31.25C48.3333 26.7857 44.7143 23.1666 40.25 23.1666H29.75ZM18.3333 31.25C18.3333 24.9447 23.4447 19.8333 29.75 19.8333H40.25C46.5552 19.8333 51.6666 24.9447 51.6666 31.25C51.6666 37.1401 47.2068 41.9877 41.4787 42.6012L31.8333 52.6403V42.6666H29.75C23.4447 42.6666 18.3333 37.5552 18.3333 31.25Z"
            fill="#0033A0"
          />
        </svg>
      );
    case '360':
      return (
        <svg
          width="70"
          height="70"
          viewBox="0 0 70 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="35" cy="35" r="35" fill="#E6EEFF" />
          <path
            d="M34.9997 26.6666C25.7997 26.6666 18.333 30.4 18.333 35C18.333 38.7333 23.233 41.8833 29.9997 42.95V48.3333L36.6663 41.6666L29.9997 35V39.55C24.7497 38.6166 21.6663 36.3833 21.6663 35C21.6663 33.2333 26.733 30 34.9997 30C43.2663 30 48.333 33.2333 48.333 35C48.333 36.2166 45.8997 38.15 41.6663 39.2166V42.6333C47.5497 41.35 51.6663 38.4166 51.6663 35C51.6663 30.4 44.1997 26.6666 34.9997 26.6666Z"
            fill="#0033A0"
          />
        </svg>
      );

    case 'person':
      return (
        <svg
          width="70"
          height="70"
          viewBox="0 0 70 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="35" cy="35" r="35" fill="#E6EEFF" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.9993 26.6666C39.9993 29.428 37.7608 31.6666 34.9993 31.6666C32.2379 31.6666 29.9993 29.428 29.9993 26.6666C29.9993 23.9052 32.2379 21.6666 34.9993 21.6666C37.7608 21.6666 39.9993 23.9052 39.9993 26.6666ZM43.3327 26.6666C43.3327 31.269 39.6017 34.9999 34.9993 34.9999C30.397 34.9999 26.666 31.269 26.666 26.6666C26.666 22.0642 30.397 18.3333 34.9993 18.3333C39.6017 18.3333 43.3327 22.0642 43.3327 26.6666ZM44.9994 44.9999C44.9994 42.2385 42.7608 39.9999 39.9994 39.9999H29.9993C27.2379 39.9999 24.9993 42.2385 24.9993 44.9999V51.6666H21.666V44.9999C21.666 40.3975 25.397 36.6666 29.9993 36.6666H39.9994C44.6017 36.6666 48.3327 40.3975 48.3327 44.9999V51.6666H44.9994V44.9999Z"
            fill="#0033A0"
          />
        </svg>
      );
    case 'trend':
      return (
        <svg
          width="70"
          height="70"
          viewBox="0 0 70 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="35" cy="35" r="35" fill="#E6EEFF" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.1575 22.412L50.1761 20.9213L45.9485 29.3764L36.7819 24.3764L30.981 35.9781L22.7618 31.0466L16.9043 40.8091L19.7626 42.5241L23.9051 35.6199L32.3526 40.6884L38.2184 28.9569L47.385 33.9569L53.1575 22.412ZM18.3333 51.6667H51.6667V48.3333H18.3333V51.6667Z"
            fill="#0033A0"
          />
        </svg>
      );

    case 'phone':
      return (
        <svg
          width="70"
          height="70"
          viewBox="0 0 70 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="35" cy="35" r="35" fill="#E6EEFF" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.334 21.6666V48.3333H26.6673L26.6673 21.6666L43.334 21.6666ZM23.334 18.3333H46.6673L46.6673 51.6666H23.334V18.3333ZM35.0007 46.6666C35.9211 46.6666 36.6673 45.9204 36.6673 44.9999C36.6673 44.0794 35.9211 43.3333 35.0007 43.3333C34.0802 43.3333 33.334 44.0794 33.334 44.9999C33.334 45.9204 34.0802 46.6666 35.0007 46.6666Z"
            fill="#0033A0"
          />
        </svg>
      );

    case 'trail':
      return (
        <svg
          width="70"
          height="70"
          viewBox="0 0 70 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="35" cy="35" r="35" fill="#E6EEFF" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.3333 26.6667C29.2538 26.6667 30 25.9205 30 25C30 24.0795 29.2538 23.3333 28.3333 23.3333C27.4129 23.3333 26.6667 24.0795 26.6667 25C26.6667 25.9205 27.4129 26.6667 28.3333 26.6667ZM33.0488 26.6667C32.5467 28.0874 31.4207 29.2133 30 29.7155V38.6178C31.942 39.3042 33.3333 41.1563 33.3333 43.3333C33.3333 46.0948 31.0948 48.3333 28.3333 48.3333C27.3461 48.3333 26.4257 48.0472 25.6505 47.5533L19.1906 51.4293L17.4756 48.571L23.5854 44.9051C23.4218 44.4108 23.3333 43.8824 23.3333 43.3333C23.3333 41.1563 24.7247 39.3042 26.6667 38.6178V29.7155C24.7247 29.0291 23.3333 27.177 23.3333 25C23.3333 22.2386 25.5719 20 28.3333 20C30.5104 20 32.3624 21.3913 33.0488 23.3333H41.9512C42.6376 21.3913 44.4896 20 46.6667 20C49.4281 20 51.6667 22.2386 51.6667 25C51.6667 27.177 50.2753 29.0291 48.3333 29.7155V38.3333H45V29.7155C43.5793 29.2133 42.4533 28.0874 41.9512 26.6667H33.0488ZM46.6667 26.6667C45.7462 26.6667 45 25.9205 45 25C45 24.0795 45.7462 23.3333 46.6667 23.3333C47.5871 23.3333 48.3333 24.0795 48.3333 25C48.3333 25.9205 47.5871 26.6667 46.6667 26.6667ZM28.3333 45C29.2538 45 30 44.2538 30 43.3333C30 42.4129 29.2538 41.6667 28.3333 41.6667C27.4129 41.6667 26.6667 42.4129 26.6667 43.3333C26.6667 44.2538 27.4129 45 28.3333 45Z"
            fill="#0033A0"
          />
        </svg>
      );
    default:
      break;
  }
};

const FeaturesCardLP = ({icon, title, text}) => {
  const iconToRender = loadLogo (icon);

  return (
    <div className="features-card-lp-container">
      <div className="features-card-lp-icon">{iconToRender}</div>
      <div className="features-card-lp-title typo-lp-features-title">
        {title}
      </div>
      <div className="features-card-lp-text typo-lp-features-text">{text}</div>
    </div>
  );
};

export default FeaturesCardLP;
