import React from 'react'
import '../../styles/components/landing-page/static-banner-lp.scss'
import Img from 'gatsby-image'


const StaticBannerLP = props => {
  return (
    <div className="banner-container">
      <div className="banner-image-wrapper">
        <Img fluid={props.image.childImageSharp.fluid} />
      </div>       
    </div>
  )
}

export default StaticBannerLP
