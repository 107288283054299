import React, {useState, useEffect} from 'react';
import Layout from '../components/layout';
import {graphql} from 'gatsby';
import SEO from '../components/SEO/SEO';
import website from '../utils/site-config';
import '../styles/utils/general.scss';
import '../styles/templates/landing-page.scss';
import StaticBannerLP from '../components/LandingPage/StaticBannerLP';
import BannersSkinLP from '../components/LandingPage/BannerSkinLP';
import TestimonialSlider from '../components/LandingPage/TestimonialSlider';
import {isTouchDevice} from '../utils/helpers';
import ScrollInteractionLogo from '../components/Pano/ScrollInteractionLogo';
import TrustWall from '../components/LandingPage/TrustWall';
import FooterLP from '../components/LandingPage/FooterLP';
import FormLP from '../components/LandingPage/FormLP';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import FeaturesLP from '../components/LandingPage/FeaturesLP';
import ReactGA from 'react-ga';
import {makeScrollWaypointBottom} from '../utils/helpers';

const TemplateLP = ({
  pageContext: {locale, folderName, path},
  data,
  location,
}) => {
  const fileFrontmatter = data.file.childMarkdownRemark.frontmatter;

  const [scrollInteraction, setScrollInteraction] = useState (false);
  const [hasPlayedVideo, setHasPlayedVideo] = useState (false);
  const [hasEndedVideo, setHasEndedVideo] = useState (false);
  const [hasReadTillEnd, setHasReadTillEnd] = useState (false);

  const playVideo = () => {
    vid.play ();
  };

  if (typeof window !== `undefined`) {
    var vid = document.getElementById ('product-video');
  }

  if (vid) {
    vid.addEventListener ('playing', () => setHasPlayedVideo (true));

    vid.addEventListener ('ended', () => setHasEndedVideo (true));
  }

  const handleScroll = () => {
    let footerWaypoint = makeScrollWaypointBottom ('.landing-page-footer');

    if (footerWaypoint <= 0) setHasReadTillEnd (true);
  };

  useEffect (
    () => {
      if (hasReadTillEnd) {
        ReactGA.event ({
          category: 'Landing Page',
          action: 'readFullContentLandingPage',
        });
      }
    },
    [hasReadTillEnd]
  );

  useEffect (() => {
    window.addEventListener ('scroll', handleScroll);

    return () => {
      window.removeEventListener ('scroll', handleScroll);
    };
  });

  useEffect (
    () => {
      if (hasPlayedVideo === true) {
        ReactGA.event ({
          category: 'Landing Page',
          action: 'Video PLayed in Session',
        });
      }
    },
    [hasPlayedVideo]
  );

  useEffect (
    () => {
      if (hasEndedVideo === true) {
        ReactGA.event ({
          category: 'Landing Page',
          action: 'Video Ended in Session',
        });
      }
    },
    [hasEndedVideo]
  );

  return (
    <Layout
      path={fileFrontmatter.path}
      locale={locale}
      templateType={fileFrontmatter.template}
    >
      <SEO
        title={`${fileFrontmatter.title} | ${website._title}`}
        pathname={fileFrontmatter.path}
        desc={fileFrontmatter.description}
        banner={fileFrontmatter.image.publicURL}
        showCase
      />

      <div
        id="landing-page-banner"
        onWheel={() => setScrollInteraction (true)}
        onTouchStart={
          typeof window !== `undefined` && isTouchDevice ()
            ? () => setScrollInteraction (true)
            : undefined
        }
      >
        <StaticBannerLP image={fileFrontmatter.image} />
        <BannersSkinLP
          bannerTitle={fileFrontmatter.bannerTitle}
          bannerSubtitle={fileFrontmatter.bannerSubtitle}
          bannerCTA={fileFrontmatter.bannerCTA}
        />
        {typeof window !== `undefined` &&
          !isTouchDevice () &&
          <ScrollInteractionLogo
            scrollInteraction={scrollInteraction}
            scrollInteractionSet={setScrollInteraction}
            locale={locale}
          />}
      </div>
      <div id="landing-page-product">
        <div className="landing-page-product-content">
          <div className="video-product-wrapper">

            <video
              id="product-video"
              controls={hasPlayedVideo}
              preload="auto"
              style={{width: `100%`, height: `auto`}}
            >
              <source
                src={fileFrontmatter.videoProductId.publicURL}
                type="video/mp4"
              />
            </video>
            {!hasPlayedVideo &&
              vid &&
              <div className="play-button-wrapper">
                <svg
                  onClick={() => playVideo ()}
                  width="86"
                  height="86"
                  viewBox="0 0 86 86"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="43"
                    cy="43"
                    r="43"
                    fill="white"
                    fillOpacity="0.9"
                  />
                  <path
                    d="M53 43L38 51.6603L38 34.3397L53 43Z"
                    fill="#0033A0"
                  />
                </svg>
              </div>}
          </div>
          <div className="text-product-wrapper">
            <div className="text-product-title typo-lp-title-secondary">
              {fileFrontmatter.productTitleText}
            </div>
            <div className="text-product-subtitle typo-lp-subtitle-secondary">
              {fileFrontmatter.productSubtitleText}
            </div>

            <AnchorLink
              href="#landing-page-form-section"
              style={{textDecoration: 'none'}}
              onClick={() =>
                ReactGA.event ({
                  category: 'Landing Page',
                  action: 'CTA Product',
                })}
            >
              <div className="text-product-cta typo-lp-product-cta">
                {fileFrontmatter.productCTA}
              </div>
            </AnchorLink>

          </div>
        </div>
      </div>
      <div id="landing-page-features">
        <FeaturesLP content={fileFrontmatter.features} />
      </div>
      <div id="landing-page-testimonials">
        <TestimonialSlider
          testimonials={fileFrontmatter.testimonials}
          bgImage={fileFrontmatter.testimonialBgImg}
          title={fileFrontmatter.testimonialTitle}
        />
      </div>
      <div id="landing-page-trust-wall">
        <TrustWall companyLogos={fileFrontmatter.trustLogos} />
      </div>
      <div id="landing-page-form-section">
        <div className="landing-page-form-section-content">
          <div className="form-section-title typo-lp-title-secondary">
            {fileFrontmatter.formSectionTitle}
          </div>
          <div className="form-section-subtitle typo-lp-subtitle-secondary">
            {fileFrontmatter.formSectionSubtitle}
          </div>

        </div>
        <FormLP locale={locale} />
      </div>
      <div className="landing-page-footer">
        <FooterLP locale={locale} />
      </div>
    </Layout>
  );
};

export default TemplateLP;

export const query = graphql`
  query TemplateLP($locale: String, $folderName: String) {
    file(name: { eq: $locale }, relativeDirectory: { eq: $folderName }) {
      childMarkdownRemark {
        frontmatter {
          title
          trustLogos
          template
          videoProductId{
              publicURL            
          }
          path
          description
          bannerTitle
          bannerSubtitle
          bannerCTA
          productTitleText
          productSubtitleText
          productCTA
          features{
            icon
            title
            text
          }
          formSectionTitle
          formSectionSubtitle
          testimonials{
              id
              text
              name
              company
              role
          }
          testimonialTitle
          image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1036) {
                ...GatsbyImageSharpFluid
              }
            }
          }
           testimonialBgImg{
            publicURL
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1036) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

/*

      <div
        className="case--banner-wrapper"
        onWheel={() => setScrollInteraction (true)}
          onTouchStart={
            typeof window !== `undefined` && isTouchDevice ()
              ? () => setScrollInteraction (true)
              : undefined
          }
      >
        <Banner3D
          banner3D={fileFrontmatter.banner3D}
          title={fileFrontmatter.title}
          productTypeShort={fileFrontmatter.productTypeShort}
          segmentTag={fileFrontmatter.segmentTag}
          areaTag={fileFrontmatter.areaTag}
          solutionTag={fileFrontmatter.solutionTag}
          productType={fileFrontmatter.productType}
          scrollInteraction={scrollInteraction}
        />
      </div>









     <div
        className="static-banner-container"
        onWheel={() => setScrollInteraction (true)}
          onTouchStart={
            typeof window !== `undefined` && isTouchDevice ()
              ? () => setScrollInteraction (true)
              : undefined
          }
      >
        <VideoBanner
          videoBannerId={fileFrontmatter.videoBannerId}
          title={fileFrontmatter.title}
          productTypeShort={fileFrontmatter.productTypeShort}
          segmentTag={fileFrontmatter.segmentTag}
          areaTag={fileFrontmatter.areaTag}
          solutionTag={fileFrontmatter.solutionTag}
          productType={fileFrontmatter.productType}
          scrollInteraction={scrollInteraction}
        />
      </div>



   <div className="component3d-container">
        <Component3D
          externalLink3DComponent={fileFrontmatter.externalLink3DComponent}
          messageFor3DComponent={fileFrontmatter.messageFor3DComponent}
          project3D={fileFrontmatter.project3D}
          backgroundImage3D={fileFrontmatter.backgroundImage3D}
        />
      </div>


   <div
          className="case--banner-wrapper"
          onWheel={() => setScrollInteraction (true)}
            onTouchStart={
            typeof window !== `undefined` && isTouchDevice ()
              ? () => setScrollInteraction (true)
              : undefined
          }
        >
          <Banner3D
            banner3D={fileFrontmatter.banner3D}
            title={fileFrontmatter.title}
            productTypeShort={fileFrontmatter.productTypeShort}
            segmentTag={fileFrontmatter.segmentTag}
            areaTag={fileFrontmatter.areaTag}
            solutionTag={fileFrontmatter.solutionTag}
            productType={fileFrontmatter.productType}
                       scrollInteraction={scrollInteraction}
            scrollInteractionSet={setScrollInteraction}
          />
        </div>




      <PanoCarousel360
  panos={fileFrontmatter.panoCarousel360}
  messageFor360Gallery={fileFrontmatter.messageFor360Gallery}
  externalLink360Gallery={fileFrontmatter.externalLink360Gallery}
  panoCarouselFolder={fileFrontmatter.panoCarouselFolder}
/>;




      <div
        className="static-banner-container"
        onWheel={() => setScrollInteraction (true)}
      >
        <VideoBanner
          videoBannerId={fileFrontmatter.videoBannerId}
          title={fileFrontmatter.title}
          productTypeShort={fileFrontmatter.productTypeShort}
          segmentTag={fileFrontmatter.segmentTag}
          areaTag={fileFrontmatter.areaTag}
          solutionTag={fileFrontmatter.solutionTag}
          productType={fileFrontmatter.productType}
                     scrollInteraction={scrollInteraction}
            scrollInteractionSet={setScrollInteraction}
        />
      </div>
      <Testimonial
        text={fileFrontmatter.caseContentRecommendation}
        name={fileFrontmatter.caseContentRecommendationName}
        role={fileFrontmatter.caseContentRecommendationRole}
      />
      <PanoCarousel360
        panos={fileFrontmatter.panoCarousel360}
        messageFor360Gallery={fileFrontmatter.messageFor360Gallery}
        externalLink360Gallery={fileFrontmatter.externalLink360Gallery}
        panoCarouselFolder={fileFrontmatter.panoCarouselFolder}
      />
*/
