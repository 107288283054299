import React from 'react';
import '../../styles/components/landing-page/footer-lp.scss';

const lpLogo = (
  <svg
    width="196"
    height="42"
    viewBox="0 0 196 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M15.0847 5.47586L12.0627 0.285583L9.05332 5.47586L6.0314 10.6661L3.02197 15.8688H9.05332L12.0627 10.6661L15.0847 15.8688H21.116L18.0941 10.6661L15.0847 5.47586Z"
        fill="white"
      />
      <path
        d="M6.03134 21.0591L3.02192 15.8688L0 21.0591H6.03134Z"
        fill="white"
      />
      <path
        d="M12.0627 21.0591L15.0846 15.8688H9.05322L12.0627 21.0591Z"
        fill="white"
      />
      <path
        d="M24.1376 21.0591L21.1157 15.8688L18.0938 21.0591H24.1376Z"
        fill="white"
      />
      <path
        d="M111.898 0.260742H116.506L107.103 21.0591H103.432L94.0288 0.260742H98.7865L105.305 15.3597L111.898 0.260742Z"
        fill="white"
      />
      <path
        d="M118.154 21.0591V0.260742H122.737V21.0591H118.154Z"
        fill="white"
      />
      <path
        d="M129.68 20.699C128.219 20.2769 127.008 19.6933 126.046 18.9358L127.52 15.6702C129.543 17.0733 131.791 17.7686 134.275 17.7686C135.612 17.7686 136.636 17.5576 137.347 17.1354C138.059 16.7132 138.421 16.1296 138.421 15.3722C138.421 14.7141 138.097 14.1926 137.435 13.8076C136.773 13.4227 135.624 13.0626 133.988 12.7149C132.153 12.3424 130.679 11.8954 129.593 11.3615C128.506 10.8276 127.707 10.1695 127.208 9.39962C126.708 8.62977 126.458 7.67367 126.458 6.51889C126.458 5.2772 126.808 4.14726 127.507 3.1539C128.207 2.16055 129.181 1.3907 130.429 0.831935C131.678 0.273173 133.127 0 134.775 0C136.261 0 137.697 0.211088 139.058 0.645681C140.432 1.08027 141.531 1.66387 142.342 2.39647L140.869 5.66212C139.033 4.25901 136.998 3.56366 134.787 3.56366C133.614 3.56366 132.677 3.79958 131.965 4.25901C131.266 4.73085 130.904 5.36412 130.904 6.1588C130.904 6.63064 131.041 7.01557 131.304 7.32599C131.566 7.63642 132.015 7.90959 132.64 8.15793C133.264 8.40627 134.163 8.64219 135.312 8.87811C137.997 9.46171 139.932 10.2191 141.119 11.1628C142.305 12.0941 142.892 13.373 142.892 14.9872C142.892 16.9367 142.13 18.4764 140.607 19.6188C139.083 20.7611 136.948 21.3323 134.238 21.3323C132.665 21.3199 131.141 21.1088 129.68 20.699Z"
        fill="white"
      />
      <path
        d="M146.238 21.0591V0.260742H150.821V21.0591H146.238Z"
        fill="white"
      />
      <path
        d="M159.2 20.0285C157.664 19.1593 156.477 17.9176 155.641 16.3034C154.804 14.6892 154.392 12.8019 154.392 10.6413C154.392 8.48077 154.804 6.60581 155.641 5.00403C156.477 3.40224 157.664 2.17296 159.2 1.30378C160.736 0.434593 162.546 0 164.619 0C166.692 0 168.503 0.434593 170.026 1.30378C171.55 2.17296 172.723 3.40224 173.548 5.00403C174.372 6.60581 174.784 8.48077 174.784 10.6413C174.784 12.8019 174.372 14.6892 173.548 16.3034C172.723 17.9176 171.55 19.1593 170.026 20.0285C168.503 20.8977 166.692 21.3323 164.619 21.3323C162.546 21.3199 160.736 20.8853 159.2 20.0285ZM168.752 15.9185C169.739 14.6892 170.238 12.926 170.238 10.6289C170.238 8.33177 169.739 6.58098 168.752 5.37653C167.766 4.17209 166.392 3.56366 164.619 3.56366C162.846 3.56366 161.485 4.17209 160.486 5.37653C159.499 6.58098 159 8.33177 159 10.6289C159 12.9509 159.499 14.7141 160.486 15.9309C161.472 17.1478 162.846 17.7562 164.619 17.7562C166.392 17.7562 167.766 17.1478 168.752 15.9185Z"
        fill="white"
      />
      <path
        d="M191.679 0.260742H196V21.0591H192.591L182.164 7.79782V21.0591H177.881V0.260742H181.265L191.692 13.4972V0.260742H191.679Z"
        fill="white"
      />
      <path
        d="M26.2231 21.0602V0.261841H30.806V17.3475H40.1464V21.0602H26.2231Z"
        fill="white"
      />
      <path d="M42.9312 21.059V0.26062H47.514V21.059H42.9312Z" fill="white" />
      <path
        d="M67.0438 0.26062H71.6516L62.2487 21.059H58.5774L49.187 0.26062H53.9447L60.463 15.3596L67.0438 0.26062Z"
        fill="white"
      />
      <path
        d="M73.3003 21.059V0.26062H87.7231V3.79945H77.7707V8.70413H87.1112V12.2678H77.7707V17.5202H87.7231V21.059H73.3003Z"
        fill="white"
      />
      <path
        d="M124.236 30.9557C124.748 31.2786 125.135 31.738 125.422 32.3216C125.697 32.9052 125.847 33.5881 125.847 34.3704C125.847 35.1527 125.709 35.8356 125.422 36.444C125.135 37.0524 124.735 37.5119 124.236 37.8471C123.724 38.1824 123.149 38.3438 122.5 38.3438C121.938 38.3438 121.439 38.2196 121.002 37.9837C120.565 37.7478 120.24 37.4125 120.015 36.9779V38.2196H118.167V27.2058H120.028V31.8001C120.252 31.3779 120.59 31.0551 121.027 30.8191C121.464 30.5832 121.951 30.4715 122.5 30.4715C123.149 30.4715 123.724 30.6329 124.236 30.9557ZM123.437 36.2205C123.774 35.7859 123.949 35.1651 123.949 34.3828C123.949 33.6005 123.774 33.0045 123.437 32.5823C123.099 32.1602 122.612 31.9491 121.976 31.9491C121.339 31.9491 120.852 32.1602 120.515 32.5948C120.177 33.0294 120.003 33.6378 120.003 34.4201C120.003 35.2147 120.177 35.8232 120.515 36.2578C120.852 36.6799 121.351 36.891 121.988 36.891C122.612 36.8786 123.087 36.6551 123.437 36.2205Z"
        fill="white"
      />
      <path
        d="M134.538 30.6698L131.004 38.7284C130.604 39.6596 130.092 40.3426 129.468 40.7772C128.844 41.2117 128.082 41.4849 127.17 41.6339L126.746 40.1936C127.457 40.0321 127.994 39.8335 128.344 39.5851C128.694 39.3368 128.993 38.9767 129.206 38.5049L129.443 37.9461L126.284 30.6573H128.257L130.442 36.0836L132.665 30.6573H134.538V30.6698Z"
        fill="white"
      />
      <path
        d="M146.226 33.6746C146.563 34.1216 146.725 34.6804 146.725 35.3385C146.725 36.2697 146.376 37.0023 145.689 37.5362C145.002 38.0702 144.078 38.3433 142.917 38.3433C142.193 38.3433 141.506 38.2316 140.831 38.0081C140.17 37.7846 139.62 37.4866 139.183 37.1017L139.845 35.6985C140.794 36.4311 141.793 36.8036 142.842 36.8036C143.529 36.8036 144.028 36.6671 144.365 36.3939C144.702 36.1207 144.865 35.711 144.865 35.1646C144.865 34.6307 144.69 34.2333 144.353 33.9726C144.016 33.7243 143.491 33.5877 142.779 33.5877H141.368V32.048H142.53C143.916 32.048 144.603 31.5265 144.603 30.4834C144.603 29.9992 144.453 29.6267 144.153 29.3659C143.853 29.1052 143.429 28.981 142.892 28.981C141.93 28.981 140.969 29.3535 140.007 30.0861L139.358 28.683C139.795 28.2981 140.332 28 140.994 27.7765C141.656 27.553 142.33 27.4413 143.017 27.4413C144.066 27.4413 144.915 27.702 145.539 28.2111C146.164 28.7202 146.476 29.4156 146.476 30.2723C146.463 30.8559 146.313 31.3775 146.014 31.7996C145.714 32.2342 145.302 32.5446 144.777 32.7433C145.414 32.9172 145.889 33.2276 146.226 33.6746Z"
        fill="white"
      />
      <path
        d="M148.798 27.5778H152.744C154.505 27.5778 155.866 28.0372 156.828 28.9685C157.789 29.8997 158.276 31.2035 158.276 32.8922C158.276 34.5933 157.789 35.8971 156.828 36.8284C155.854 37.7596 154.493 38.2315 152.744 38.2315H148.798V27.5778ZM152.619 36.6545C155.092 36.6545 156.316 35.4004 156.316 32.8798C156.316 30.384 155.079 29.1423 152.619 29.1423H150.734V36.6545H152.619Z"
        fill="white"
      />
      <path
        d="M166.768 34.5436H161.535C161.573 35.3507 161.773 35.9467 162.147 36.3316C162.509 36.7166 163.059 36.9028 163.783 36.9028C164.62 36.9028 165.381 36.6297 166.106 36.0957L166.643 37.3871C166.281 37.6727 165.843 37.9086 165.307 38.0824C164.782 38.2563 164.245 38.3432 163.708 38.3432C162.459 38.3432 161.485 37.9955 160.774 37.3002C160.062 36.6048 159.7 35.6487 159.7 34.4319C159.7 33.662 159.85 32.9791 160.162 32.3831C160.474 31.7871 160.911 31.3152 161.473 30.98C162.035 30.6447 162.672 30.4833 163.383 30.4833C164.432 30.4833 165.257 30.8185 165.856 31.489C166.468 32.1596 166.768 33.0908 166.768 34.258V34.5436ZM162.197 32.2589C161.885 32.5569 161.673 32.9791 161.585 33.5378H165.119C165.057 32.9667 164.882 32.5445 164.595 32.2465C164.308 31.9609 163.92 31.8119 163.408 31.8119C162.921 31.8243 162.522 31.9733 162.197 32.2589Z"
        fill="white"
      />
      <path
        d="M169.752 37.8592C169.165 37.5363 168.728 37.0893 168.416 36.5057C168.103 35.9221 167.954 35.2392 167.954 34.4445C167.954 33.6622 168.116 32.9669 168.441 32.3709C168.765 31.7749 169.227 31.303 169.827 30.9677C170.426 30.6325 171.113 30.4711 171.9 30.4711C172.437 30.4711 172.961 30.558 173.473 30.7194C173.985 30.8932 174.385 31.1292 174.684 31.4272L174.16 32.7434C173.835 32.4826 173.498 32.2839 173.136 32.1474C172.774 32.0108 172.424 31.9487 172.062 31.9487C171.375 31.9487 170.851 32.1598 170.464 32.5819C170.076 33.0041 169.889 33.6126 169.889 34.4197C169.889 35.2143 170.076 35.8228 170.451 36.2449C170.826 36.6671 171.363 36.8782 172.049 36.8782C172.399 36.8782 172.761 36.8037 173.123 36.6671C173.485 36.5305 173.823 36.3319 174.147 36.0711L174.672 37.3873C174.347 37.6853 173.935 37.9212 173.423 38.0951C172.911 38.2689 172.374 38.3434 171.812 38.3434C171.013 38.3434 170.326 38.182 169.752 37.8592Z"
        fill="white"
      />
      <path
        d="M175.808 27.2428H177.893V29.0805H175.808V27.2428ZM175.933 38.2194V30.6699H177.794V38.2194H175.933Z"
        fill="white"
      />
      <path
        d="M187.122 27.2179V38.2317H185.273V37.0148C185.049 37.437 184.712 37.7598 184.287 37.9958C183.85 38.2317 183.363 38.3434 182.813 38.3434C182.164 38.3434 181.577 38.182 181.065 37.8468C180.553 37.5115 180.154 37.0521 179.866 36.4437C179.579 35.8352 179.442 35.1523 179.442 34.37C179.442 33.5878 179.579 32.9048 179.866 32.3212C180.141 31.7376 180.541 31.2782 181.053 30.9554C181.565 30.6325 182.152 30.4711 182.813 30.4711C183.35 30.4711 183.837 30.5829 184.262 30.8064C184.687 31.0299 185.011 31.3527 185.248 31.75V27.2054H187.122V27.2179ZM184.749 36.2326C185.099 35.798 185.273 35.1895 185.273 34.4073C185.273 33.625 185.099 33.0166 184.761 32.582C184.424 32.1474 183.937 31.9363 183.313 31.9363C182.676 31.9363 182.189 32.1474 181.852 32.5696C181.502 32.9917 181.327 33.6002 181.327 34.37C181.327 35.1523 181.502 35.7731 181.852 36.2077C182.202 36.6423 182.676 36.8658 183.3 36.8658C183.925 36.8782 184.399 36.6672 184.749 36.2326Z"
        fill="white"
      />
      <path
        d="M195.813 34.5436H190.581C190.618 35.3507 190.818 35.9467 191.193 36.3316C191.555 36.7166 192.104 36.9028 192.828 36.9028C193.665 36.9028 194.427 36.6297 195.151 36.0957L195.688 37.3871C195.326 37.6727 194.889 37.9086 194.352 38.0824C193.827 38.2563 193.29 38.3432 192.754 38.3432C191.505 38.3432 190.531 37.9955 189.819 37.3002C189.107 36.6048 188.745 35.6487 188.745 34.4319C188.745 33.662 188.895 32.9791 189.207 32.3831C189.519 31.7871 189.956 31.3152 190.518 30.98C191.08 30.6447 191.717 30.4833 192.429 30.4833C193.478 30.4833 194.302 30.8185 194.901 31.489C195.513 32.1596 195.813 33.0908 195.813 34.258V34.5436ZM191.255 32.2589C190.943 32.5569 190.731 32.9791 190.643 33.5378H194.177C194.115 32.9667 193.94 32.5445 193.653 32.2465C193.365 31.9609 192.978 31.8119 192.466 31.8119C191.979 31.8243 191.567 31.9733 191.255 32.2589Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="196" height="41.634" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const FooterLP = ({locale}) => {
  const copyrightText = locale === 'en'
    ? 'All rights reserved.'
    : 'Todos os direitos reservados.';

  return (
    <footer className="footer-lp-container">
      <div className="footer-lp-brand-block">
        <div className="footer-lp-logo">
          {lpLogo}
        </div>
        <div className="footer-lp-copyright-notice typo-lp-footer-notice
">
          2021 © {copyrightText}
        </div>
      </div>
      <div className="footer-lp-contacts">
        <div className="footer-lp-contacts-title typo-lp-footer-contacts">
          {locale === 'en' ? 'Contacts' : 'Contatos'}
        </div>
        <div className="footer-lp-contacts-email footer-lp-contacts-caption typo-lp-footer-captions">
          info@3decide.com
        </div>
        <div className="footer-lp-contacts-phone footer-lp-contacts-caption
 typo-lp-footer-captions">
          +351 223 205 834
        </div>
        <div className="footer-lp-contacts-web footer-lp-contacts-caption typo-lp-footer-captions">
          www.alive.vision
        </div>
      </div>

    </footer>
  );
};

export default FooterLP;
