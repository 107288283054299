import React from 'react';
//import remark from 'remark';
//import remarkHtml from 'remark-html';
import '../../styles/components/landing-page/testimonial-block-lp.scss';

const TestimonialBlockLP = ({text, name, company, role}) => {
  return (
    <div className="testimonial-block-lp-container">
      <div className="testimonial-block-lp-text typo-lp-testimonial-text">
        "{text}"
      </div>
      <div className="testimonial-block-lp-author">
        <div className="testimonial-block-lp-name-company typo-lp-testimonial-author">
          {name}, {company}
        </div>
        <div
          className="testimonial-block-lp-role typo-lp-testimonial-role"
          style={{color: '#DCDCDC'}}
        >
          {role}
        </div>
      </div>
    </div>
  );
};

export default TestimonialBlockLP;
