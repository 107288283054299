import React from 'react';
import '../../styles/components/landing-page/testimonial-block-lp.scss';
import cmporto from '.././../images/company-logos/cmporto.svg';
import cmlisboa from '.././../images/company-logos/cmlisboa.svg';
import avila from '.././../images/company-logos/avila.svg';
import nos from '.././../images/company-logos/nos.svg';
import vidaimobiliaria from '.././../images/company-logos/vidaimobiliaria.svg';
import uporto from '.././../images/company-logos/uporto.svg';
import lipor from '.././../images/company-logos/lipor.svg';
import '../../styles/components/landing-page/trust-wall.scss';

const TrustWall = ({companyLogos}) => {
  const loadLogo = (logoName, i) => {
    switch (logoName) {
      case 'cmporto':
        return (
          <div key={i} className="trust-wall-logo-container">
            <img src={cmporto} alt={logoName} />
          </div>
        );
      case 'cmlisboa':
        return (
          <div key={i} className="trust-wall-logo-container">
            <img src={cmlisboa} alt={logoName} />
          </div>
        );
      case 'nos':
        return (
          <div key={i} className="trust-wall-logo-container">
            <img src={nos} alt={logoName} />
          </div>
        );
      case 'avila':
        return (
          <div key={i} className="trust-wall-logo-container">
            <img src={avila} alt={logoName} />
          </div>
        );
      case 'uporto':
        return (
          <div key={i} className="trust-wall-logo-container">
            <img src={uporto} alt={logoName} />
          </div>
        );
      case 'vidaimobiliaria':
        return (
          <div key={i} className="trust-wall-logo-container">
            <img src={vidaimobiliaria} alt={logoName} />
          </div>
        );
      case 'lipor':
        return (
          <div key={i} className="trust-wall-logo-container">
            <img src={lipor} alt={logoName} />
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div className="trust-wall-container">
      {companyLogos.map ((d, i) => loadLogo (d, i))}
    </div>
  );
};

export default TrustWall;
